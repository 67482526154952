import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseParamsRequest, TrendPipedriveAPIService } from '@trendbuild/trend-pipedrive-api';
import { AbstractComponent, Integration, IntegrationService, IntegrationTypeEnum, PipedriveIntegration, Company } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-pipedrive-integration-form',
  templateUrl: './pipedrive-integration-form.component.html',
  styleUrls: ['./pipedrive-integration-form.component.scss'],
})
export class PipedriveFormComponent extends AbstractComponent implements OnInit {
  private trendPipedriveAPIService: TrendPipedriveAPIService = new TrendPipedriveAPIService();

  integration: Integration;
  hideAllPasswords: boolean = true;
  apiToken: string | null = null;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService
  ) {
    super(injector)
    this.integration = <Integration>{
      type: IntegrationTypeEnum.PIPEDRIVE,
      actived: true,
      removed: false,
      metadata: <PipedriveIntegration>{},
      company: <Company>{},
    }
    this.setupForm();
  }

  ngOnInit() {
    this.loadingContent = true;
    this.isNew = true;
    const id = this.getParam('id');

    if (!!id) {
      this.isNew = false;
      this.getIntegrationById(id);
    } else {
      this.loadingContent = false;
    }
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      metadata: new FormGroup({
        api_token: new FormControl('', [Validators.required]),
      }),
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.integration = Object.assign(this.integration, value)
      });
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService
      .getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration
          this.formGroup.patchValue(this.integration)
          this.loadingContent = false
        },
        error: (err) => {
          this.alertService.error(err.error.message)
          this.loadingContent = false
        },
      });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return
    }

    this.loading = true;
    if (this.isNew) {
      const currentCompany = this.getCompanyCurrentUser();
      if (currentCompany) {
        this.integration.company = currentCompany;
      }

      const baseParamsRequest: BaseParamsRequest = { api_token: this.integration.metadata['api_token'] };

      const result = await this.trendPipedriveAPIService.listUsers(baseParamsRequest);

      if (result.success) {
        this.integrationService
          .create(this.integration)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.alertService.success('Integração criada com sucesso');
              this.goBack();
            },
            error: (err) => {
              this.alertService.error(err.error.message);
              this.loading = false;
            },
          });
      } else {
        this.alertService.error('O token fornecido é inválido');
        this.loading = false;
      }
    } else {
      const baseParamsRequest: BaseParamsRequest = { api_token: this.integration.metadata['api_token'] };

      const result = await this.trendPipedriveAPIService.listUsers( baseParamsRequest );

      if (result.success) {
        this.integrationService
          .update(this.integration._id, this.integration)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.alertService.success('Integração atualizada com sucesso');
              this.goBack();
            },
            error: (err) => {
              this.alertService.error(err.error.message);
              this.loading = false;
            },
          })
      } else {
        this.alertService.error('O token fornecido é inválido');
        this.loading = false;
      }
    }
  }

  togglePasswordVisibility(): void { this.hideAllPasswords = !this.hideAllPasswords };

  cancel() { this.router.navigate(['/integrations/pipedrive/list']) };
}
