import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseRequest, TrendOpenAIAPIService } from '@trendbuild/trend-openai-api';
import { AbstractComponent, Integration, IntegrationService, IntegrationTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-openai-integration-form',
  templateUrl: './openai-integration-form.component.html',
  styleUrls: ['./openai-integration-form.component.scss']
})
export class OpenAIFormComponent extends AbstractComponent implements OnInit {

  private trendOpenAIAPIService: TrendOpenAIAPIService = new TrendOpenAIAPIService();

  integration: Integration;
  hideAllPasswords: boolean = true;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
  ) {
    super(injector);
    this.integration = <Integration>{
      type: IntegrationTypeEnum.OPENAI
    };
    this.setupForm();
  }

  ngOnInit() {
    this.isNew = true;
    const id = super.getParam('id');
    if (!!id) {
      this.isNew = false;
      this.getIntegrationById(id);
    }
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      metadata: new FormGroup({
        token: new FormControl('', [Validators.required]),
        organization: new FormControl('', [Validators.required]),
        project: new FormControl('', [Validators.required]),
      }),
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.integration = Object.assign(this.integration, value);
    });
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService.getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.formGroup.patchValue(this.integration);
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    };

    this.loading = true;
    if (this.isNew) {
      const baseRequest: BaseRequest =  { apiKey : this.integration.metadata[`token`] };
      const result = await this.trendOpenAIAPIService.listModels(baseRequest);
      
      if(result.data.length > 0){
      this.integrationService.create(this.integration).subscribe({
        next: () => {
          this.alertService.success();
          this.goBack();
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false,
      });
    } else {
      this.alertService.error('O token fornecido é inválido');
      this.loading = false;
    }
    } else {
      const baseRequest: BaseRequest =  { apiKey : this.integration.metadata[`token`] };
      const result = await this.trendOpenAIAPIService.listModels(baseRequest);

      if(result.data.length > 0){
      this.integrationService.update(this.integration._id, this.integration).subscribe({
        next: () => {
          this.alertService.success();
          this.goBack();
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false,
      });

    } else {
      this.alertService.error('O token fornecido é inválido');
      this.loading = false;
    }
    }
  }

  copyApiKey() {
    const apiKey = this.formGroup.get('apiKey')?.value;
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
    }
  }

  togglePasswordVisibility(): void {
    this.hideAllPasswords = !this.hideAllPasswords;
  }

  cancel() {
    this.router.navigate(['/integrations/openai/list']);
  }

  goToAssistants() {
    this.router.navigate(['/integrations/openai/assistants', this.integration._id]);
  }

}
