import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersObject } from '@trendbuild/trend-agendor-api/src/lib/models/users.types';
import { AbstractComponent, AgendorIntegration, Company, Integration, IntegrationService, IntegrationTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-agendor-integration-form',
  templateUrl: './agendor-integration-form.component.html',
  styleUrls: ['./agendor-integration-form.component.scss'],
})
export class AgendorFormComponent extends AbstractComponent implements OnInit {
  integration: Integration;
  hideAllPasswords: boolean = true;
  apiToken: string;
  userCurrent: UsersObject;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService
  ) {
    super(injector)
    this.integration = <Integration>{
      type: IntegrationTypeEnum.AGENDOR,
      actived: true,
      removed: false,
      metadata: <AgendorIntegration>{},
      company: <Company>{},
    }
    this.setupForm()
  }

  ngOnInit() {
    this.loadingContent = true;
    this.isNew = true;
    const id = this.getParam('id');

    if (!!id) {
      this.isNew = false;
      this.getIntegrationById(id);
    } else {
      this.loadingContent = false;
    }
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      metadata: new FormGroup({
        token: new FormControl('', [Validators.required]),
      }),
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.integration = Object.assign(this.integration, value);
      });
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService
      .getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.formGroup.patchValue(this.integration);
          this.loadingContent = false;
        },
        error: (err) => {
          this.alertService.error(err.error.message);
          this.loadingContent = false;
        },
      });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    this.loading = true;

    if (this.isNew) {
      const currentCompany = this.getCompanyCurrentUser();
      if (currentCompany) {
        this.integration.company = currentCompany;
      }

      const token = this.integration.metadata['token'];

      this.integrationService.getAgendorUsers(token).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (userCurrent) => {
          if (!userCurrent.data) {
            this.alertService.error('O token fornecido é inválido');
            this.loading = false;
            return;
          }

          this.userCurrent = userCurrent.data;

          this.integrationService.create(this.integration).pipe(
            takeUntil(this.destroy$)
          ).subscribe({
            next: () => {
              this.alertService.success('Integração criada com sucesso');
              this.goBack();
            },
            error: (err) => {
              this.alertService.error(err.error?.message || 'Erro ao criar integração');
            },
            complete: () => {
              this.loading = false;
            }
          });
        },
        error: (err) => {
          this.alertService.error(err.error?.message || 'Erro ao validar token');
          this.loading = false;
        }
      });

    } else {
      const token = this.integration.metadata['token'];

      this.integrationService.getAgendorUsers(token).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (userCurrent) => {
          if (!userCurrent.data) {
            this.alertService.error('O token fornecido é inválido');
            this.loading = false;
            return;
          }

          this.userCurrent = userCurrent.data;

          this.integrationService.update(this.integration._id, this.integration).pipe(
            takeUntil(this.destroy$)
          ).subscribe({
            next: () => {
              this.alertService.success('Integração atualizada com sucesso');
              this.goBack();
            },
            error: (err) => {
              this.alertService.error(err.error?.message || 'Erro ao atualizar integração');
            },
            complete: () => {
              this.loading = false;
            }
          });
        },
        error: (err) => {
          this.alertService.error(err.error?.message || 'Erro ao validar token');
          this.loading = false;
        }
      });
    }
  }

  togglePasswordVisibility(): void {
    this.hideAllPasswords = !this.hideAllPasswords;
  }

  cancel() {
    this.router.navigate(['/integrations/agendor/list']);
  }
}
