import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { BodyComponentObject, ButtonsComponentObject, FooterComponentObject, HeaderComponentObject, TemplateMessageObject } from '@trendbuild/trend-cloud-api'
import { AbstractComponent, AlertModalComponent, AttendanceService, FirebaseService, MessageService, MetadataMessage, UploadTypeEnum, UtilHelper, WebhookIntegrations, WebhookIntegrationsAction, WebhookIntegrationsService } from 'lib-trend-core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'webhook-integration-wa-template-message',
  templateUrl: './webhook-integrations-wa-template-message.component.html',
  styleUrls: ['./webhook-integrations-wa-template-message.component.scss'],
})
export class WebhookWaTemplateMessageComponent extends AbstractComponent implements OnInit {

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false)
  loadingSpinnerTemplate$ = this.loadingSpinnerSubject.asObservable()

  file: File;
  fileZero: any;
  fileZeroSrc: string;
  typeFile: string;

  selectedTemplate: TemplateMessageObject;

  @Input() set template(template: TemplateMessageObject) {
    this.selectedTemplate = template;
    this.extractTemplateVariables();
  }
  @Input() webhookRequests: object;
  @Input() webhookIntegrations: WebhookIntegrations;
  @Output() templateParams = new EventEmitter<MetadataMessage>()

  previewText: string
  previewFooter: string
  previewHeader: any

  constructor(
    injector: Injector,
    public messageService: MessageService,
    private firebaseService: FirebaseService,
    public attendanceService: AttendanceService,
    public webhookIntegrationsService: WebhookIntegrationsService,
    private modalAlert: MatDialog,
  ) {
    super(injector);
    this.setupForm();
  }

  ngOnInit(): void {
    const actions: Array<WebhookIntegrationsAction> = this.webhookIntegrations.actions
    actions?.map((action: WebhookIntegrationsAction) => {
      if (action.typeMessage === 'template' && action.template.id === this.selectedTemplate.id) {

        (this.formGroup.get('headers') as FormArray).clear();
        (this.formGroup.get('bodys') as FormArray).clear();

        action.templateParamsHeader?.map((param, index) => {
          (this.formGroup.get('headers') as FormArray).push(
            new FormGroup({
              value: new FormControl(param, [Validators.required]),
            })
          );
        });

        action.templateParamsBody?.map((param, index) => {
          (this.formGroup.get('bodys') as FormArray).push(
            new FormGroup({
              value: new FormControl(param, [Validators.required]),
            })
          );
        });

      }
    });
  }

  public get headerComponent(): HeaderComponentObject {
    return <HeaderComponentObject>this.selectedTemplate?.components.find(c => c.type === 'HEADER');
  }

  public get bodyComponent(): BodyComponentObject {
    return <BodyComponentObject>this.selectedTemplate?.components.find(c => c.type === 'BODY');
  }

  public get footerComponent(): FooterComponentObject {
    return <FooterComponentObject>this.selectedTemplate?.components.find(c => c.type === 'FOOTER');
  }

  public get buttonsComponent(): ButtonsComponentObject {
    return <ButtonsComponentObject>this.selectedTemplate?.components.find(c => c.type === 'BUTTONS');
  }

  get headers() {
    return this.formGroup.controls['headers'] as FormArray;
  }

  get bodys() {
    return this.formGroup.controls['bodys'] as FormArray;
  }

  get headerValue() {
    return this.headers.controls[0].get('value').value;
  }

  private setupForm(): void {
    this.formGroup = new FormGroup({
      headers: new FormArray([]),
      bodys: new FormArray([]),
    });

    this.formGroup.valueChanges.subscribe((value) => {
      this.updatePreviewText();
      this.updateHeaderText();
      this.templateParams.emit(<MetadataMessage>{
        template: this.selectedTemplate,
        headerParams: this.headers.controls.map((control: FormGroup) => control.get('value').value),
        bodyParams: this.bodys.controls.map((control: FormGroup) => control.get('value').value),
        previewText: this.previewText
      });
    })
  }

  private updateHeaderText() {
    if (this.headerComponent) {
      if (!this.isMedia) {
        this.previewHeader = this.headerComponent.text
        if (this.headerComponent.example?.header_text?.length > 0 || this.headerComponent.example?.header_handle?.length > 0) {
          this.headers.controls.map((control: FormGroup, index: number) => {
            const value = control.get('value').value
            if (value) {
              this.previewHeader = this.previewHeader.replace(`{{${index + 1}}}`, value)
            }
          })
        }
      }
    }
  }

  private updatePreviewText() {
    this.previewText = this.bodyComponent.text
    if (this.previewText) {
      this.bodys.controls.map((control: FormGroup, index: number) => {
        const value = control.get('value').value
        if (value) {
          this.previewText = this.previewText.replace(`{{${index + 1}}}`, value)
        }
      })
    }
  }

  public get isMedia() {
    return (!!this.headerComponent && this.headerComponent!.format !== 'TEXT');
  }

  public get webhookRequestKeys(): string[] {
    return Object.keys(this.webhookRequests ?? []);
  }

  extractTemplateVariables(): void {
    (this.formGroup.get('headers') as FormArray).clear();
    (this.formGroup.get('bodys') as FormArray).clear();

    if (this.headerComponent) {
      if (this.isMedia) {
        (this.formGroup.get('headers') as FormArray).push(
          new FormGroup({
            value: new FormControl(null, [Validators.required]),
          })
        );
      } else {
        if (this.headerComponent.example?.header_text?.length > 0 || this.headerComponent.example?.header_handle?.length > 0) {
          (this.formGroup.get('headers') as FormArray).push(
            new FormGroup({
              value: new FormControl(null, [Validators.required]),
            })
          );
        }
      }
    }

    if (this.bodyComponent) {
      const numberVariables = this.bodyComponent.example?.body_text[0]?.length;
      for (let i = 1; i <= numberVariables; i++) {
        (this.formGroup.get('bodys') as FormArray).push(
          new FormGroup({
            value: new FormControl(null, [Validators.required]),
          })
        );
      }
      this.previewText = this.bodyComponent.text;
    }

    if (this.footerComponent) {
      this.previewFooter = this.footerComponent.text;
    }
  }

  updateValueForm(value: string, index: number, element: string) {
    if (element === 'header') {
      if (this.isHeaderDocument() || this.isHeaderVideo() || this.isHeaderImage()) {
        let requestValue = this.webhookRequests[value];
        requestValue = requestValue?.replace(/ /g, "%20").replace(/\n/g, "");
        if (!this.domainHttpRegex.test(requestValue)) {
          this.openModalAlert("A variável escolhida não é uma URL, é necessário que seja uma URL para que seja adicionado ao template!", '')
          return;
        }
        this.headers.controls[index].get('value').setValue(`{{${value}}}`);
      } else {
        this.headers.controls[index].get('value').setValue(`{{${value}}}`);
      }
      this.updateHeaderText();
    } else if (element === 'body') {
      this.bodys.controls[index].get('value').setValue(`{{${value}}}`);
      this.updatePreviewText();
    }
  }

  updateValueFormOperatorName(index: number, element: string): void {
    if (element === 'header') {
      this.headers.controls[index].get('value').setValue(this.getCurrentUserUser().name);
      this.updateHeaderText();
    } else if (element === 'body') {
      this.bodys.controls[index].get('value').setValue(this.getCurrentUserUser().name);
      this.updatePreviewText();
    }
  }

  onSelectImagem(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];

    if (this.file) {
      const fileType = this.file.type;
      const fileSize = this.file.size;
      const extensionFile = UtilHelper.getFileExtension(fileType);
      this.typeFile = UtilHelper.getMimeType(extensionFile);

      const fileTypeLimit = UtilHelper.getFileTypeLimit(fileType);
      if (fileTypeLimit) {
        if (fileSize > fileTypeLimit.maxSize) {
          this.openModalAlert(
            fileTypeLimit.alertTitle,
            fileTypeLimit.alertMessage,
            target
          )
          return;
        }
      } else {
        this.openModalAlert('Tipo de arquivo não suportado', '', target);
        return;
      }

      let reader = new FileReader();
      reader.onload = async (file) => {
        if (this.file) {
          this.loadingSpinnerSubject.next(true);
          const contents = file.target as FileReader;
          const base64 = UtilHelper.arrayBufferToBase64(contents.result);
          const dateMilisecond = new Date().getTime();
          const filename: string = dateMilisecond.toString();
          this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.MESSAGE)
            .then((snapshot: { url: string }) => {
              this.loadingSpinnerSubject.next(false);
              this.headers.controls[0].get('value').setValue(snapshot.url);
            })
        }
      }
      reader.readAsArrayBuffer(this.file);
    }
  }

  private openModalAlert(
    title: string,
    message: string,
    inputElement?: HTMLInputElement
  ): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '500px',
      data: { title: title, message: message },
    })
    if (inputElement) {
      dialogRefAlert.afterClosed().subscribe(() => {
        inputElement.value = ''
      })
    }
  }

  acceptFiles(): string {
    let accept = '';
    if (this.isHeaderDocument()) {
      accept = '.pdf';
    } else if (this.isHeaderVideo()) {
      accept = '.mp4';
    } else if (this.isHeaderImage()) {
      accept = '.jpeg, .jpg, .png';
    }
    return accept;
  }

  isHeaderDocument() {
    return this.headerComponent.format === 'DOCUMENT';
  }

  isHeaderVideo() {
    return this.headerComponent.format === 'VIDEO';
  }

  isHeaderImage() {
    return this.headerComponent.format === 'IMAGE';
  }

  getIconForContentType(): string {
    let icon = '';
    if (this.isHeaderDocument()) {
      icon = UtilHelper.on_pdf;
    } else if (this.isHeaderVideo()) {
      icon = UtilHelper.on_video;
    }
    return icon;
  }

}
