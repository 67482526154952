<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Integrações OpenAI - Assistentes Integrados</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Organize seus assistentes</h6>
    <hr />
  </div>

  <div class="w-full flex justify-between items-center mb-4">
    <h2 class="text-xl font-semibold">&nbsp;</h2>
    <button *ngIf="!loadingContent && assistants.length > 0"
      class="ml-4 w-[fit-content] px-3 h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
      (click)="openCreateAssistantModal()">
      + Adicionar Assistente
    </button>
  </div>

  <content-loading-list *ngIf="loadingContent"></content-loading-list>

  <div class="w-full max-h-[calc(100vh-240px)]" *ngIf="!loadingContent && assistants.length > 0">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-2 border-gray-100">

      <table class="min-w-full divide-gray-100 divide-y-[3px]">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-4 py-3 text-left text-sm font-light text-slate-500">Data de Criação</th>
            <th class="px-4 py-3 text-left text-sm font-light text-slate-500">Nome do Assistente</th>
            <th class="px-4 py-3 text-left text-sm font-light text-slate-500">Canais Vinculados</th>
            <th class="px-4 py-3 text-right text-sm font-light text-slate-500"></th>
          </tr>
        </thead>
        <tbody class="divide-gray-100 divide-y-[3px] bg-white">
          <tr *ngFor="let assistantObj of assistants" class="rounded-none cursor-pointer">
            <td class="whitespace-nowrap px-3 py-4">
              <div class="flex gap-2">
                <div class="flex flex-col">
                  <div class="font-weight-600">
                    {{ assistantObj.created_at * 1000 | date: 'dd/MM/yyyy' }}
                  </div>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <div class="font-weight-600">{{ assistantObj.name || 'Sem Nome' }}</div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <span>{{getChannelsByIdAssistant(assistantObj.id)}}</span>
            </td>

            <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <div class="inline-block text-left" x-data="{ menu: false }">
                <button x-on:click="menu = ! menu; $event.stopPropagation();" type="button"
                  class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button"
                  aria-expanded="true" aria-haspopup="true">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </button>
                <div x-show="menu" x-on:click.away="menu = false"
                  class="origin-top-right absolute right-20 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="" role="none">
                    <a href="javascript:void(0)"
                      class="text-red-600 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                      role="menuitem" tabindex="-1" id="menu-item-0" (click)="openEditAssistantModal(assistantObj)">
                      Editar
                    </a>
                  </div>
                  <div class="" role="none">
                    <a href="javascript:void(0)"
                      class="text-red-600 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                      role="menuitem" tabindex="-1" id="menu-item-0" (click)="deleteAssistant(assistantObj)">
                      Remover
                    </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <empty-records-component *ngIf="!loadingContent && assistants.length == 0"
    [message]="'Não existem assistentes cadastrados'">
  </empty-records-component>

</div>