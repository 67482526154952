import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BodyComponentObject, TemplateMessageObject, TemplateMessageObjectPager, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel, ChannelService } from 'lib-trend-core';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'webhook-integration-wa-template',
  templateUrl: './webhook-integrations-wa-template.component.html',
  styleUrls: ['./webhook-integrations-wa-template.component.scss'],
})
export class WebhookWaTemplateComponent extends AbstractComponent {

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.getList();
  }

  @Output() onSelectTemplate = new EventEmitter<TemplateMessageObject>();

  selectedTemplate: TemplateMessageObject = null
  templates: Array<TemplateMessageObject> = new Array<TemplateMessageObject>()
  selectedChannel: Channel

  private trendCloudAPIService: TrendCloudAPIService = new TrendCloudAPIService()

  constructor(injector: Injector, private channelService: ChannelService) {
    super(injector);
  }

  async getList() {
    if (!this.selectedChannel) return
    this.channelService.getTemplatesByChannel(this.selectedChannel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (templateMessageObjectPager: TemplateMessageObjectPager) => {
          this.templates = templateMessageObjectPager.data;
        }
      });
  }

  showTemplateDetails(template: TemplateMessageObject) {
    this.onSelectTemplate.emit(template)
  }

  getBodyText(template: TemplateMessageObject): string {
    const bodyComponent = <BodyComponentObject>template?.components.find(c => c.type === 'BODY');
    return bodyComponent.text;
  }

}
