import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Integration, IntegrationService, IntegrationTypeEnum, Pager } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'openai-integrations-list-component',
  templateUrl: 'openai-integration-list.component.html',
  providers: [DatePipe],
})
export class OpenAIListComponent extends AbstractComponent implements OnInit {

  pager: Pager<Integration> = new Pager<Integration>({ perPage: 10 });
  listObservable: Observable<Pager<Integration>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  override searchString: string;

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private integrationService: IntegrationService
  ) {
    super(injector);
    this.loadingContent = true;
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loadingContent = true;

    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: IntegrationTypeEnum.OPENAI,
    };

    this.integrationService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(takeUntil(this.destroy$),)
      .subscribe({
        next: (pager: Pager<Integration>) => {
          this.pager = pager;
          this.setupObservableSearch();
        },
        complete: () => (this.loadingContent = false),
      });
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        return this.integrationService.getAll(
          this.pager.page,
          this.pager.perPage,
          term,
          this.searchParams
        );
      })
    );

    this.listObservable.subscribe({
      next: (pager: Pager<Integration>) => {
        this.pager = pager
      }
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  delete(webhookId: string, name: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
      data: `Tem certeza que deseja remover a  integração? "${name}"?`
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.integrationService.delete(webhookId).subscribe({
          next: () => {
            this.getList();
            this.alertService.success();
          },
          error: (err) => this.alertService.error(err.error.message),
        })
      }
    });
  }

  removeIntegration(integration: Integration) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '700px',
      data: `Tem certeza que deseja remover a integração "${integration.name}"?`
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.integrationService.delete(integration._id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.alertService.success('Integração removida com sucesso');
              this.getList();
            },
            error: (error) => {
              const errorMessage = error?.error?.message || 'Ocorreu um erro ao remover a integração';
              this.alertService.error(errorMessage);
              this.loading = false;
            }
          });
      }
    });
  }

}
