import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Integration, IntegrationService, Pager, IntegrationTypeEnum } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'agendor-integrations-list-component',
  templateUrl: 'agendor-integration-list.component.html',
  providers: [DatePipe]
})
export class AgendorListComponent extends AbstractComponent implements OnInit {

  pager: Pager<Integration> = new Pager<Integration>({ perPage: 10 });
  listObservable: Observable<Pager<Integration>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  override searchString: string;
  processedList: Integration[] = [];


  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private integrationService: IntegrationService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loadingContent = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: IntegrationTypeEnum.AGENDOR
    };
    this.integrationService.getAll(this.pager.page, this.pager.perPage, this.searchString, {
      company: this.getIDCurrentCompany()
    })
      .pipe(
        takeUntil(this.destroy$),
        map((pager: Pager<Integration>) => {
          const agendorIntegrations = pager.list.filter(
            integration => integration.type === IntegrationTypeEnum.AGENDOR
          );

          return {
            ...pager,
            list: agendorIntegrations,
            total: agendorIntegrations.length
          };
        })
      )
      .subscribe({
        next: (pager: Pager<Integration>) => {
          this.pager = pager;
          this.processedList = pager.list;
          this.setupObservableSearch();
        },
        error: (err) => {
          this.loadingContent = false;
        },
        complete: () => (this.loadingContent = false),
      });
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        return this.integrationService.getAll(
          this.pager.page,
          this.pager.perPage,
          term,
          this.searchParams
        )
      })
    )

    this.listObservable.subscribe({
      next: (pager: Pager<Integration>) => {
        this.pager = pager
        this.processedList = pager.list.filter((item) =>
          item.type === IntegrationTypeEnum.AGENDOR &&
          item.name.toLowerCase().includes(this.searchString.toLowerCase())
        )
      },
      error: (err) => {
        console.error('Error in listObservable:', err)
      },
    })
  }

  removeIntegration(integration: Integration) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '700px',
      data: `Tem certeza que deseja remover a integração "${integration.name}"?`
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingContent = true;
        this.integrationService.delete(integration._id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.alertService.success('Integração removida com sucesso');
              this.getList();
            },
            error: (error) => {
              const errorMessage = error?.error?.message || 'Ocorreu um erro ao remover a integração';
              this.alertService.error(errorMessage);
              this.loadingContent = false;
            }
          });
      }
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  editIntegration(integration: Integration) {
    this.router.navigate([`/integrations/agendor/${integration._id}/edit`]);
  }

  goLeadRules(integration: Integration) {
    this.router.navigate([`/integrations/agendor/${integration._id}/rules`]);
  }
}
