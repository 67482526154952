<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Integrações OpenAI - {{isNew ? 'Adicionar' : 'Alterar'}} Assistente
    </h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Configure seu assistente OpenAI</h6>
    <hr />
  </div>

  <div class="w-full">

    <form [formGroup]="formGroup" style="padding: 5px;">
      <div class="flex w-full flex-col">
        <div class="flex gap-2">
          <div class="flex flex-col flex-1">
            <label class="font-medium text-black">Nome *</label>
            <input class="input-field" maxlength="100" formControlName="name"
              placeholder="Digite o nome do assistente..." required>
            <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
            </field-error-component>
          </div>
        </div>

        <label class="font-medium text-black mt-3">Modelo *</label>
        <mat-select class="input-field" formControlName="model" (selectionChange)="onModelSelection($event)"
          placeholder="Selecione um modelo...">
          <mat-option *ngFor="let model of models" [value]="model.value">
            {{ model.label }}
          </mat-option>
        </mat-select>

        <div class="flex flex-col w-full mt-[15px]">
          <label class="font-medium text-black">Descrição *</label>
          <input class="input-field" maxlength="200" formControlName="description" placeholder="Digite uma descrição..."
            required>
          <field-error-component [field]="'description'" [label]="'Descrição'" [formGroup]="formGroup">
          </field-error-component>
        </div>

        <div class="flex flex-col w-full mt-[15px]">
          <label class="font-medium text-black">Prompt *</label>
          <textarea class="input-textarea h-[120px]" style="width: 100%; resize: none; outline: none;" maxlength="32768"
            formControlName="instructions" placeholder="Digite as Prompt para o assistente..."></textarea>
          <field-error-component [field]="'instructions'" [label]="'Prompt'" [formGroup]="formGroup">
          </field-error-component>
        </div>

        <div class="flex flex-col w-full mt-[15px]">
          <label class="font-medium text-black">Selecionar Canal *</label>
          <mat-select class="input-field" formControlName="channels" multiple placeholder="Selecione um canal">
            <mat-option *ngFor="let channel of channels" [value]="channel._id">{{ channel.name }}</mat-option>
          </mat-select>
          <field-error-component class="absolute mt-[80px]" [field]="'channels'" [label]="'channel'"
            [formGroup]="formGroup">
          </field-error-component>
        </div>
      </div>
    </form>
  </div>

  <div class="flex justify-end items-center w-full gap-4 mt-3 mb-4">
    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      (click)="goBack()" [disabled]="loading">
      Cancelar
    </button>

    <button
      class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] hover:bg-[#1340f6] focus:outline-none"
      (click)="onSubmit()" [disabled]="loading">
      <ng-container *ngIf="!loading">Salvar</ng-container>
      <ng-container *ngIf="loading">
        <mat-spinner [diameter]="25" [color]="'accent'" mode="indeterminate"></mat-spinner>
      </ng-container>
    </button>
  </div>

</div>