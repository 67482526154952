import { Component, Injector, OnInit } from '@angular/core';
import { AssistantListRequest, AssistantObject, TrendOpenAIAPIService } from '@trendbuild/trend-openai-api';
import { AbstractComponent, Channel, ConfirmationComponent, Integration, IntegrationService, OpenAIAssistant } from 'lib-trend-core';
import { takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-openai-integration-assistants',
  templateUrl: './openai-integration-assistants.component.html',
  styleUrls: ['./openai-integration-assistants.component.scss']
})
export class OpenAIAssistantsComponent extends AbstractComponent implements OnInit {

  private trendOpenAIAPIService: TrendOpenAIAPIService = new TrendOpenAIAPIService();

  integration: Integration;
  assistants: AssistantObject[] = [];
  channels: Channel[] = [];
  selectedChannel: Channel;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
    private dialog: MatDialog,

  ) {
    super(injector);
    this.loadingContent = true;
  }

  ngOnInit() {
    const id = super.getParam('id');
    if (!!id) {
      this.getIntegrationById(id);
    }
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService.getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.loadData();
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  async loadData() {
    this.loadingContent = true;

    const assistantListRequest: AssistantListRequest = {
      apiKey: this.integration.metadata['token'],
      organization: this.integration.metadata['organization'],
      project: this.integration.metadata['project'],
    };

    const assistants = await this.trendOpenAIAPIService.listAssistants(assistantListRequest);
    if (!!assistants && assistants.data?.length > 0) {
      this.assistants = assistants.data;
    }
    this.loadingContent = false;
  }

  deleteAssistant(assistant: AssistantObject) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
      data: `Tem certeza que deseja remover o assistente "${assistant.name}"?`
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (Boolean(result) === true) {
        await this.trendOpenAIAPIService.deleteAssistant({
          apiKey: this.integration.metadata['token'],
          organization: this.integration.metadata['organization'],
          project: this.integration.metadata['project'],
          assistant_id: assistant.id,
        })

        await this.integrationService.deleteAssistant(this.integration._id, assistant.id).toPromise();
        this.loadData();
      }
    });
  }

  openEditAssistantModal(assistants: AssistantObject) {
    this.router.navigate([`/integrations/openai/assistant/${this.integration._id}/edit/${assistants.id}`]);
  }

  openCreateAssistantModal() {
    this.router.navigate([`integrations/openai/assistant/${this.integration._id}/create`]);
  }

  getChannelsByIdAssistant(idAssistant: string): string[] {
    const assistant = this.integration.metadata?.['assistants']?.find((a: OpenAIAssistant) => a.id === idAssistant);
    return assistant?.channels?.map((c: any) => c.name) || [];
  }

}
