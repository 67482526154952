import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AssistantCreateRequest, AssistantObject, AssistantUpdateRequest, ModelObject, TrendOpenAIAPIService } from '@trendbuild/trend-openai-api';
import { AbstractComponent, Channel, ChannelService, Integration, IntegrationService, OpenAIIntegration } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

interface ItemOption {
  label: string;
  value: string;
};

@Component({
  selector: 'app-assistant-create',
  templateUrl: './assistant-create.component.html',
  styleUrls: ['./assistant-create.component.scss'],
})
export class AssistantCreateComponent extends AbstractComponent implements OnInit {

  private trendOpenAIAPIService: TrendOpenAIAPIService = new TrendOpenAIAPIService();

  assistants: AssistantObject[] = [];
  assistant: AssistantObject;
  models: ItemOption[] = [];
  selectedModel: string | null = null;
  channels: Channel[] = [];
  selectedChannels: string[] = [];
  selectedChannel: Channel;
  integration: Integration;

  constructor(
    injector: Injector,
    private channelService: ChannelService,
    private integrationService: IntegrationService,
  ) {
    super(injector);
    this.setupForm();
    this.assistant = <AssistantObject>{};
  }

  ngOnInit() {
    this.isNew = true;
    const idIntegration = this.getParam('id');
    const idAssistant = this.getParam('idassistant');
    this.loadChannels();

    if (!!idIntegration) {
      this.getIntegrationById(idIntegration, idAssistant);
    }
    if (!!idAssistant) {
      this.isNew = false;
    }

  }

  getIntegrationById(idIntegration: string, idAssistant: string) {
    this.integrationService.getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.loadModels();
          this.getAssistantById(idAssistant);
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }


  getAssistantById(idAssistant: string) {
    this.trendOpenAIAPIService.getAssistantById({
      apiKey: this.integration.metadata['token'],
      organization: this.integration.metadata['organization'],
      project: this.integration.metadata['project'],
      assistant_id: idAssistant,
    })
      .then((assistant: AssistantObject) => {
        this.assistant = assistant;
        this.formGroup.patchValue(this.assistant);
        const metadata: OpenAIIntegration = <OpenAIIntegration>this.integration.metadata;
        const assistants = metadata.assistants?.filter((a) => a.id === idAssistant) || [];
        const channels = assistants?.map((assistant) => assistant.channels.map((channel) => channel._id)).flat();
        this.formGroup.patchValue({ channels: channels ?? [] });
      })
      .catch((err) => this.alertService.error(err.error.message));
  }

  async loadModels() {

    try {
      if (!this.integration) {
        this.alertService.info('Nenhuma integração configurada');
        return
      };

      const metadata = this.integration.metadata;
      if (!metadata || !metadata['token']) {
        console.error('Token de API não configurado');
        return
      };

      const models = await this.trendOpenAIAPIService.listModels({
        apiKey: metadata['token'],
        organization: metadata['organization'],
        project: metadata['project'],
      });

      this.models = models.data.map((model: ModelObject) => ({
        label: model.id,
        value: model.id,
      }));

    } catch (error) {
      console.error('Erro completo ao carregar modelos:', error);
      this.alertService.error('Não foi possível carregar os modelos');
    }
  }

  loadChannels() {
    this.channelService.getList().subscribe({
      next: (channels) => {
        this.channels = channels;
        if (this.assistant) {
          this.populateForm(this.assistant);
        }
      },
      error: (error) => {
        this.alertService.error('Erro ao carregar canais.');
      },
    });
  }

  onModelSelection(event: MatSelectChange) {
    const selectedModel = event.value;
    this.formGroup.get('model')?.setValue(selectedModel);
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      instructions: new FormControl('', [Validators.required]),
      channels: new FormControl([]),
    });

    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.assistant, value);
    });
  }

  populateForm(assistant: AssistantObject) {
    const assistantMetadata = assistant.metadata || {};
    const channels = assistantMetadata['channels'] || [];
    this.selectedChannels = channels.map((channel: any) => channel._id);
    this.formGroup.patchValue({
      name: assistant.name || '',
      model: assistant.model || '',
      description: assistant.description || '',
      instructions: assistant.instructions || '',
    });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    };

    this.loading = true

    const metadata = this.integration.metadata
    const apiKey = metadata['token']
    const organization = metadata['organization']
    const project = metadata['project']

    const channels = this.formGroup.get('channels')?.value.map((idChannel: string) => {
      const channel = this.channels.find(c => c._id === idChannel);
      return { _id: channel._id, name: channel.name, type: channel.type };
    });

    const newAssistent = <Partial<AssistantCreateRequest | AssistantUpdateRequest>>{
      apiKey: apiKey,
      organization: organization,
      project: project,
      assistant_id: this.assistant.id,
      model: this.assistant.model,
      name: this.assistant.name,
      description: this.assistant.description,
      instructions: this.assistant.instructions,
    };

    try {
      if (this.isNew) {
        const assistantCreated = await this.trendOpenAIAPIService.createAssistant(<AssistantCreateRequest>newAssistent);
        this.setupChannelsAssistant(assistantCreated.id, channels, assistantCreated.name);
      } else {
        await this.trendOpenAIAPIService.updateAssistant(<AssistantUpdateRequest>newAssistent);
        this.setupChannelsAssistant(this.assistant.id, channels, this.assistant.name);
      }
    } catch (err) {
      this.alertService.error(err.error?.message || 'Erro ao salvar assistente');
      this.loading = false;
    }
  }

  setupChannelsAssistant(assistantId: string, channels: string[], assistantName: string) {
    const metadata = this.integration.metadata;
    const assistants = metadata['assistants'] ?? [];
    const index = assistants.findIndex(a => a.id === assistantId);
    if (index >= 0) {
      assistants[index] = { name: assistantName, id: assistantId, channels: channels };
    } else {
      assistants.push({ name: assistantName, id: assistantId, channels: channels });
    }
    metadata['assistants'] = assistants;

    const updatedIntegration = { ...this.integration, metadata };
    this.integrationService.update(this.integration._id, updatedIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.success();
          this.loading = false;
          this.goBack();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
  }

}
