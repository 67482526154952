"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendOpenAIAPIService = void 0;
const tslib_1 = require("tslib");
const model_service_1 = require("./services/model.service");
const assistant_service_1 = require("./services/assistant.service");
const thread_service_1 = require("./services/thread.service");
class TrendOpenAIAPIService {
    constructor() {
        this.modelService = new model_service_1.ModelService();
        this.assistantService = new assistant_service_1.AssistantService();
        this.threadService = new thread_service_1.ThreadService();
    }
    /**
     * List assistants
     */
    listAssistants(assistantListRequest) {
        return this.assistantService.listAssistants(assistantListRequest);
    }
    /**
     * Get assistant by id
     */
    getAssistantById(assistantRetrievesRequest) {
        return this.assistantService.getAssistantById(assistantRetrievesRequest);
    }
    /**
     * Create a new assistant
     */
    createAssistant(assistantCreateRequest) {
        return this.assistantService.createAssistant(assistantCreateRequest);
    }
    /**
     * Update an existing assistant
     */
    updateAssistant(assistantUpdateRequest) {
        return this.assistantService.updateAssistant(assistantUpdateRequest);
    }
    /**
     * Delete an assistant
     */
    deleteAssistant(assistantDeleteRequest) {
        return this.assistantService.deleteAssistant(assistantDeleteRequest);
    }
    /**
     * Get list of models
     */
    listModels(baseRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const listModels = yield this.modelService.listModels(baseRequest);
            const allowedModels = new Set([
                'gpt-4o-mini',
                'gpt-4o',
                'gpt-4',
                'o1-mini',
                'gpt-4-turbo'
            ]);
            const filteredData = listModels.data ? listModels.data.filter(model => allowedModels.has(model.id)) : [];
            return {
                data: filteredData
            };
        });
    }
    /**
     * Get model by id
     */
    getModelById(modelRetrievesRequest) {
        return this.modelService.getModelById(modelRetrievesRequest);
    }
    /**
     * Create a new thread run
     *
     * @param createThreadRunRequest
     * @returns Promise<RunObject>
     */
    createThreadRun(createThreadRunRequest) {
        return this.threadService.createThreadRun(createThreadRunRequest);
    }
    /**
     * Create a new run
     *
     * @param createRunRequest
     * @returns Promise<RunObject>
     */
    createRun(createRunRequest) {
        return this.threadService.createRun(createRunRequest);
    }
    /**
     * Retrieve a run
     *
     * @param retrieveRunRequest
     * @returns
     */
    retrieveRun(retrieveRunRequest) {
        return this.threadService.retrieveRun(retrieveRunRequest);
    }
    /**
     * List run steps
     *
     * @param listRunStepRequest
     * @returns
     */
    listRunSteps(listRunStepRequest) {
        return this.threadService.listRunSteps(listRunStepRequest);
    }
    /**
     * Retrieve a message
     *
     * @param retrieveMessageRequest
     * @returns
     */
    retrieveMessage(retrieveMessageRequest) {
        return this.threadService.retrieveMessage(retrieveMessageRequest);
    }
}
exports.TrendOpenAIAPIService = TrendOpenAIAPIService;
